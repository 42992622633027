<template>
    <div class="merchandise">
        <headComp @keyChange="keyChange" v-if="!isImport"></headComp>
        <!-- <breadComp></breadComp> -->

        <div class="centerWrapper">
            <div class="interspace" v-if="!isImport">
                <div class="space">
                    <el-select v-model="goodsTypeFatherId" @change="fatherIdChange">
                        <el-option
                        v-for="item in goodsTypeOpt"
                        :key="item.goodsTypeId"
                        :label="item.goodsTypeName"
                        :value="item.goodsTypeId">
                        </el-option>
                    </el-select>
                </div>
                <div class="classify">
                    <div :class="{'item':1,'themeBackColor':item.goodsTypeId==goodsTypeChildId,'active':item.goodsTypeId==goodsTypeChildId}" v-for="(item,i) in goodsTypeLevel3List" :key="i" @click="clickLevel3Type(item)">{{item.goodsTypeName}}</div>
                </div>

                <div class="expand" @click="clickMoreFilter" v-show="filtrateCondtion.length>this.initShowLine">
                    {{isExpand?'收起更多':'更多筛选'}}
                    <i class="el-icon-arrow-down" :class="isExpand?'up':''"></i>
                </div>
            </div>
            <div class="kinds">
                <div class="lines" :style="isExpand?expandStyle:{'height':initHeight}">
                    <div class="line" v-for="(item,i) in filtrateCondtion" :key="i">
                        <!-- <div class="lineInner" :style="item.isMore?{'height':(item.isMulti?(item.subDivHeight+36):(item.subDivHeight))+'px','padding':item.filed=='brandId'?'1px 0':''}:{'height':item.filed=='brandId'?'57px':'25px','padding':item.filed=='brandId'?'1px 0':''}"> -->
                        <div class="lineInner" :style="item.isMore?{'height':(item.isMulti?pxToRem(item.subDivHeight+36):pxToRem(item.subDivHeight))+'rem','padding':item.filed=='brandId'?'1px 0':''}:{'height':item.filed=='brandId'?(pxToRem(57)+'rem'):(pxToRem(25)+'rem'),'padding':item.filed=='brandId'?'1px 0':''}">
                            <div class="kindName">{{filedMatch[item.filed]}}</div>
                            <div class="subs">
                                <!-- <div :class="{'item':1,'active':item.selected.includes(subItem.value),'isBrand':item.filed=='brandId'}" v-for="(subItem,j) in item.selectList" :key="j" @click="clickCondition(item,subItem)"> -->
                                <div :class="{'item':1,'themeBackColor':item.selected.includes(subItem.value),'active':item.selected.includes(subItem.value),'isBrand':item.filed=='brandId'}" v-for="(subItem,j) in item.selectList" :key="j" @click="clickCondition(item,subItem)">
                                    <span v-show="item.filed!='brandId'">
                                        {{subItem.label}}
                                    </span>
                                    <img v-show="item.filed=='brandId'" :src="subItem.icon"/>
                                    <div class="text" v-show="item.filed=='brandId'">{{subItem.label}}</div>
                                </div>
                                <div class="multiOpe" v-show="item.isMulti">
                                    <div class="confirm" :class="{'disabled':item.selected.length==0}" @click="clickConfirmMulti(item)">确定</div>
                                    <div class="cancel" @click="clickLineMulti(item)">取消</div>
                                </div>
                            </div>
                            <div class="opt" v-show="(item.filed!='brandId'&&item.subDivHeight>25)||(item.filed=='brandId'&&item.subDivHeight>64)">
                                <el-button :style="{'opacity':item.isMulti?'0':'1'}" class="more" :class="{'trans':item.isMore}" type="text" @click="clickLineMore(item)">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                                <el-button :style="{'opacity':item.isMulti?'0':'1'}" class="multi" :class="{'isMulti':item.isMulti}" plain round icon="el-icon-plus" @click="clickLineMulti(item)">{{item.isMulti?'单选':'多选'}}</el-button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="line">
                        <div class="kindName">风格</div>
                        <div class="subs">
                            <div :class="{'item':1,'active':i==1}" v-for="i in 6" :key="i">现代简约</div>
                        </div>
                        <div class="opt" >
                            <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                        </div>
                    </div>
                    <div class="line">
                        <div class="kindName">沙发类型</div>
                        <div class="subs">
                            <div :class="{'item':1,'active':i==1}" v-for="i in 2" :key="i">转角沙发</div>
                        </div>
                        <div class="opt" >
                            <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                        </div>
                    </div>
                    <div class="line">
                        <div class="kindName">沙发长度</div>
                        <div class="subs">
                            <div :class="{'item':1,'active':i==1}" v-for="i in 6" :key="i">2.5米以下</div>
                        </div>
                        <div class="opt" v-if="i==1||i==2">
                            <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                        </div>
                    </div>
                    <div class="line">
                        <div class="kindName">材质</div>
                        <div class="subs">
                            <div :class="{'item':1,'active':i==1}" v-for="i in 6" :key="i">皮布结合</div>
                        </div>
                        <div class="opt" v-if="i==1||i==2">
                            <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                        </div>
                    </div> -->
                </div>
                
            </div>
            <div class="filtrate">
                <div class="condition">
                    <div class="dimen">
                        <el-button type="text" :class="orderBy==0?'active':''" @click="orderBy=0;searchGoods()">综合</el-button>
                        <el-button type="text" :class="[1,2].includes(orderBy)?'active':''" @click="clickSales">销量
                            <i v-show="![1,2].includes(orderBy)" class="el-icon-d-caret el-icon--right"></i>
                            <i v-show="orderBy==1" class="el-icon-caret-top"></i>
                            <i v-show="orderBy==2" class="el-icon-caret-bottom"></i>
                        </el-button>

                        <el-button type="text" :class="[3,4].includes(orderBy)?'active':''" @click="clickNewly">最新
                            <i v-show="![3,4].includes(orderBy)" class="el-icon-d-caret el-icon--right"></i>
                            <i v-show="orderBy==3" class="el-icon-caret-top"></i>
                            <i v-show="orderBy==4" class="el-icon-caret-bottom"></i>
                        </el-button>
                        
                        <el-button type="text" :class="[5,6].includes(orderBy)?'active':''" @click="clickPrice">价格
                            <i v-show="![5,6].includes(orderBy)" class="el-icon-d-caret el-icon--right"></i>
                            <i v-show="orderBy==5" class="el-icon-caret-top"></i>
                            <i v-show="orderBy==6" class="el-icon-caret-bottom"></i>
                        </el-button>
                    </div>
                    <!-- <div class="priceRange">
                        <span>价格</span>
                        <div class="price">
                            ￥<input/>
                        </div>
                        <span class="gap"></span>
                        <div class="price">
                            ￥<input/>
                        </div>
                    </div> -->
                    <!-- <div class="sendTo">
                        <span>送至</span>
                        <el-select v-model="sendToArea" placeholder="请选择地区">
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                </div>
                <div class="view">
                    <span class="total">共{{total}}件商品</span>
                    <!-- <span class="ratio">1/4</span>
                    <div class="swi">
                        <el-button type="text"><i class="el-icon-arrow-left el-icon--right"></i></el-button>
                        <el-button type="text"><i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </div> -->
                </div>
            </div>
            <div class="container">
                <div class="item" v-for="(item,i) in goodsList" :key="i" @click="clickGoods(item)">
                    <div class="img">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                        <!-- <img src="https://img003.tianyingmeijia.com/u_g/233223/1711697985EVGGe2H5awkW2xJX6h/cmVzaXplLHdfMzIw/9ab7c28e994a165ef44108ececd11683.jpg"/> -->
                    </div>
                    <!-- <wholesalePriceComp @click.native.stop="clickWholeSale(i)" :isList="true" :ref="'wholesalePriceComp'" :goodsId="item.goodsId"> -->
                        <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                    <!-- </wholesalePriceComp> -->
                    <div class="intro">
                        <span class="tag">【{{item.brandName}}】</span>
                        {{item.name}} 
                    </div>
                    <div class="rela">
                        <div>
                            <img src="../assets/imgs/solded.png"/>
                            <span class="num">{{item.sales+item.virtualSales}}</span>
                            <span>已售</span>
                        </div>
                        <div>
                            <img src="../assets/imgs/heat.png"/>
                            <span class="num">{{item.hotValue}}</span>
                            <span>热度</span>
                        </div>

                        <div class="flag">
                            <img src="../assets/imgs/effect29.png" v-show="item.isPremium=='Y'"/>
                            <img src="../assets/imgs/effect30.png" v-show="item.isNew=='Y'"/>
                            <img src="../assets/imgs/effect31.png" v-show="item.isHot=='Y'"/>
                        </div>
                    </div>
                </div>
                
                <div class="empty" v-show="goodsList.length==0">
                    <img src="../assets/imgs/goodsEmpty.png" />
                    <div>店家正在努力上新中~</div>
                </div>
            </div>

            <!-- <div class="pagi" :style="isImport?{'margin-top':'30px'}:{}" v-if="!isImport"> -->
            <div class="pagi" :style="isImport?{'margin-top':'30px'}:{}">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>

        <footerComp v-if="!isImport"></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import wholesalePriceComp from "@/components/wholesalePriceComp"
import {getGoodsSearchSelect,getGoodsSearch,getGoodsTypeListChild,getGoodsTypeList,getGoodsTypeTree} from "@/axios/api"
export default {
    props:['isImport','inputTypeId','inputTypeIdArr'],
    components: {
        headComp,footerComp,breadComp,wholesalePriceComp
    },
    data(){
        return{
            currentPage4:1,
            pageSize:32,
            goodsTypeFatherId:"",
            goodsTypeChildId:"",
            goodsTypeOpt:[
                // {
                //     value: 1,
                //     label: '客厅'
                // }, {
                //     value: 2,
                //     label: '厨房'
                // }
            ],
            goodsTypeLevel3List:[],
            sendToArea:'',
            options2:[
                {
                    value: 1,
                    label: '上海'
                }, {
                    value: 2,
                    label: '北京'
                }
            ],
            filtrateCondtion:[],//筛选条件
            filedMatch:{
                'type':'分类',
                'brandId':'品牌',
                'style':'风格',
                'sofaMaterial':'沙发材质',
                'bedMaterial':'床榻材质',
                'mattressMaterial':'床垫材质',
                'tableMaterial':"桌几材质",
                'sofaSize':'沙发规格',
                'bedSize':"床规格",
                'mattressSize':"床垫规格",
                'tableSize':"餐桌规格",
                'sofaType':"沙发类型",
                'bedStruct':'床结构',
                'mattressThickness':'床垫厚度',
                'hardness':"软硬度",
                'mesaShape':"台面形状"
            },
            filedRep:{
                'type':'tp',
                'brandId':'bi',
                'style':'st',
                'sofaMaterial':'sfm',
                'bedMaterial':'bdm',
                'mattressMaterial':'msm',
                'tableMaterial':"tem",
                'sofaSize':'sfs',
                'bedSize':"bds",
                'mattressSize':"mss",
                'tableSize':"tes",
                'sofaType':"sft",
                'bedStruct':'bdst',
                'mattressThickness':'mst',
                'hardness':"hdn",
                'mesaShape':"mas"
            },
            goodsList:[],
            total:0,

            isExpand:false,
            initShowLine:4,//初始显示多少行
            initHeight:'',//初始高度
            expandStyle:{
                height:"145.8px"
            },
            orderBy:0,//排序方式
            fromBrand_id:"",//从品牌馆进入此页面时需要
            fromCouture_id:"",//从高定专区分类进入此页面时需要
            fromHome_id:"",//从首页分类进入时需要
            isPreferred:false,//是否优选
            triggerFromInput:false,
        }
    },
    methods: {
        clickWholeSale(i){
            // // console.log(this.$refs)
            this.$refs.wholesalePriceComp.forEach((item,j)=>{
                if(i!=j){
                    item.showWholeSalePrice=false;
                    item.showPwdBox=false;
                }
            })
        },
        handleSizeChange(val) {
            // // console.log(`每页 ${val} 条`);
            this.pageSize=val;
            this.searchGoods()
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage4=val;
            // this.searchGoods();

            if(this.isImport){
                this.searchGoods();
                return
            }
            
            if(this.filtrateCondtion.length>0){
                sessionStorage.AOGEYA_filters=JSON.stringify(this.filtrateCondtion)
            }
            var  obj=JSON.parse(JSON.stringify(this.$route.query));
            obj.p=val;
            // // console.log(obj)
            this.$router.push({
                path:"/merchandise",
                // query:{fromHome:this.$route.query.fromHome,p:val},
                query:obj,
                notNew:1
            })
            // this.$nextTick(()=>{
            //     location.reload()
            // })
            // setTimeout(()=>{
            //     location.reload()
            // },1500)
        },
        // 监听关键词发生改变
        keyChange(keyword){
            sessionStorage.removeItem('brandId');
            sessionStorage.removeItem('home_typeId');
            this.fromBrand_id='';
            this.fromHome_id='';
            this.goodsTypeChildId="";
            this.triggerFromInput=true;
            this.gainFiltrateCondition();
        },
        // 获取筛选条件
        gainFiltrateCondition(){
            var param={};
            if(this.filtrateCondtion.length>0){
                sessionStorage.AOGEYA_filters=JSON.stringify(this.filtrateCondtion);
                
                this.filtrateCondtion.forEach(item=>{
                    param[item.filed]=item.selected.join(",")
                })
                if(this.filtrateCondtion.filter(item=>item.filed=='type').length>0){
                    param.goodsTypes=this.filtrateCondtion.filter(item=>item.filed=='type')[0].selected.join(',')
                }
                if(this.filtrateCondtion.filter(item=>item.filed=='brandId').length>0){
                    param.brands=this.filtrateCondtion.filter(item=>item.filed=='brandId')[0].selected.join(',')
                }
            }
            param.keyword=sessionStorage.searchKeyword;
            // param.pageNum=this.currentPage4;
            // param.pageSize=this.pageSize;
            param.orderBy=this.orderBy;
            if(this.fromBrand_id!=''){
                param.brands=this.fromBrand_id
            }
            if(this.fromHome_id){
                param.goodsTypeIds=this.fromHome_id
            }
            if(this.goodsTypeFatherId&&!this.triggerFromInput){
                if(this.goodsTypeChildId){
                    param.goodsTypeIds=this.goodsTypeChildId
                }else{
                    param.goodsTypeIds=this.goodsTypeFatherId
                }
            }
            if(this.isImport){
                if(this.inputTypeId!=''){
                    param.goodsTypeIds=this.inputTypeId
                }else if(this.inputTypeIdArr.length>0){
                    param.goodsTypeIds=this.inputTypeIdArr.join(',')
                }
            }
            if(this.fromCouture_id!=''){
                param.isHigh="Y"
            }
            if(this.isPreferred){
                param.isPreferred="Y"
            }
            // console.log("初始",param)

            getGoodsSearchSelect(param).then(res=>{
                // console.log("筛选条件",res);
                if(res.code==200){
                    var initH=0;
                    
                    res.data.forEach(item=>{
                        // if(item.selectList.length>15){
                        //     item.selectList.splice(15)
                        // }
                        // item.selected=[item.selectList[0].value]
                        var flag=false;
                        for(var k=0;k<this.filtrateCondtion.length;k++){
                            if(this.filtrateCondtion[k].filed==item.filed){
                                item.selected=this.filtrateCondtion[k].selected;
                                flag=true;
                                break
                            }
                        }
                        if(!flag){
                            item.selected=[];
                            
                            if(item.filed=='type'&&this.fromCouture_id!=''){
                                item.selected.push(this.fromCouture_id);
                            }
                            if(item.filed=='brandId'&&this.fromBrand_id!=''){
                                item.selected.push(this.fromBrand_id)
                            }
                        }
                    })
                    this.filtrateCondtion=res.data;

                    this.filtrateCondtion.forEach((item,i)=>{
                        var div=document.createElement('div')
                        var str="<div style='width:1167px;display:flex;flex-wrap:wrap;align-items:center'>"
                        if(item.filed!='brandId'){
                            item.selectList.forEach(subItem=>{
                                str+="<div style='padding:0 10px;height:25px;margin-right:0px;font-size:14px;'>"+subItem.label+"</div>"
                            })
                        }else{
                            item.selectList.forEach(subItem=>{
                                str+="<div style='padding:0 10px;height:64px;margin-right:0px;font-size:14px;'>"+"<img style='width:100px;height:54px'/>"+"</div>"
                            })
                        }
                        str+="</div>";
                        div.innerHTML=str;
                        document.body.appendChild(div)
                        // console.log($(div).height())
                        this.$set(item,'subDivHeight',$(div).height())
                        this.$set(item,'isMore',false)
                        this.$set(item,'isMulti',false)
                        document.body.removeChild(div);

                        if(i<this.initShowLine){
                            if(item.filed=='brandId'){
                                initH+=80.6
                            }else{
                                initH+=48.6
                            }
                        }
                    })

                    // this.initHeight=initH+'px';
                    this.initHeight=this.pxToRem(initH)+'rem';
                    // console.log(initH)
                    // 计算筛选区域高度
                    var h=(res.data.length-1)*48.6+80.6;
                    // this.expandStyle.height=h+'px';
                    this.expandStyle.height=this.pxToRem(h)+'rem';

                    this.searchGoods()
                }
            })
        },
        // 返回字段名
        backFiledName(filed){
            filed=filed.toLowerCase();
            for(var key in this.filedMatch){
                if(filed.indexOf(key)!=-1){
                    return this.filedMatch[key]
                    break
                }
            }
        },
        // 点击筛选条件
        clickCondition(item,subItem){
            if(item.isMulti){
                if(item.selected.includes(subItem.value)){
                    item.selected.splice(item.selected.indexOf(subItem.value),1)
                }else{
                    item.selected.push(subItem.value)
                }
            }else{
                if(item.selected.includes(subItem.value)){
                    item.selected.splice(item.selected.indexOf(subItem.value),1)
                }else{
                    item.selected.splice(0,item.selected.length)
                    item.selected.push(subItem.value)
                }
                this.triggerFromInput=false;
                // console.log(this.filtrateCondtion)
                // this.gainFiltrateCondition()
                // this.searchGoods()

                this.redefParam();
            }
        },
        redefParam(){
            var str=""
            this.filtrateCondtion.forEach(item=>{
                if(item.selected.length>0){
                    str+='%'+this.filedRep[item.filed]+"_"+item.selected.join('-')
                }
            })
            // console.log(str)
            var query={}
            if(str.length>0){
                query={...this.$route.query,pa:str.substring(1)}
            }else{
                if(this.$route.query.pa){
                    var obj=JSON.parse(JSON.stringify(this.$route.query));
                    delete obj.pa;
                    query=obj
                }else{
                    query={...this.$route.query}
                }
            }
            this.$router.push({
                path:"/merchandise",
                query:query,
                notNew:1
            })
        },
        // 点击每行更多
        clickLineMore(line){
            line.isMore=!line.isMore;
            if(!line.isMore){
                line.isMulti=false
            }

            var h=0;
            this.filtrateCondtion.forEach((item,i)=>{
                if(!this.isExpand){
                    if(i<this.initShowLine){
                        if(item.isMore){
                            h+=item.subDivHeight+23.6;
                        }else{
                            if(item.filed=='brandId'){
                                h+=80.6
                            }else{
                                h+=48.6
                            }
                        }
                    }
                }else{
                    if(item.isMore){
                        h+=item.subDivHeight+23.6
                    }else{
                        if(item.filed=='brandId'){
                            h+=80.6
                        }else{
                            h+=48.6
                        }
                    }
                }
            })
            if(!this.isExpand){
                // this.initHeight=h+'px'
                this.initHeight=this.pxToRem(h)+'rem'
            }else{
                // this.expandStyle.height=h+'px';
                this.expandStyle.height=this.pxToRem(h)+'rem';
            }
        },
        // 点击是否多选
        clickLineMulti(line){
            line.isMulti=!line.isMulti;

            line.isMore=line.isMulti;

            var h=0;
            this.filtrateCondtion.forEach((item,i)=>{
                if(!this.isExpand){
                    if(i<this.initShowLine){
                        if(item.isMore){
                            h+=item.subDivHeight+23.6+36;
                        }else{
                            if(item.filed=='brandId'){
                                h+=80.6
                            }else{
                                h+=48.6
                            }
                        }
                    }
                }else{
                    if(item.isMore){
                        h+=item.subDivHeight+23.6+36
                    }else{
                        if(item.filed=='brandId'){
                            h+=80.6
                        }else{
                            h+=48.6
                        }
                    }
                }
            })
            if(!this.isExpand){
                // this.initHeight=h+'px'
                this.initHeight=this.pxToRem(h)+'rem'
            }else{
                // this.expandStyle.height=h+'px';
                this.expandStyle.height=this.pxToRem(h)+'rem';
            }
            
            // // console.log(line)
            // if(!line.isMulti){
            //     line.selected.splice(1,line.selected.length-1)
            // }
            // this.searchGoods();
        },
        pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
        // 点击多选确定
        clickConfirmMulti(line){
            if(line.selected.length==0){
                this.clickLineMulti(line)
            }else{
                this.triggerFromInput=false
                this.gainFiltrateCondition();
                // this.searchGoods();
            }
        },
        // 点击更多筛选
        clickMoreFilter(){
            this.isExpand=!this.isExpand
        },
        // 搜索商品
        searchGoods(){
            // this.goodsList=[];
            var param={};
            this.filtrateCondtion.forEach(item=>{
                param[item.filed]=item.selected.join(",")
            })
            if(this.filtrateCondtion.filter(item=>item.filed=='type').length!=0){
                param.goodsTypes=this.filtrateCondtion.filter(item=>item.filed=='type')[0].selected.join(',')
            }
            if(this.filtrateCondtion.filter(item=>item.filed=='brandId').length!=0){
                param.brands=this.filtrateCondtion.filter(item=>item.filed=='brandId')[0].selected.join(',')
            }
            param.keyword=sessionStorage.searchKeyword;
            param.pageNum=this.currentPage4;
            param.pageSize=this.pageSize;
            param.orderBy=this.orderBy;
            if(this.fromBrand_id!=''){
                param.brands=this.fromBrand_id
            }
            if(this.fromHome_id){
                param.goodsTypeIds=this.fromHome_id
            }
            if(this.goodsTypeFatherId&&!this.triggerFromInput){
                if(this.goodsTypeChildId){
                    param.goodsTypeIds=this.goodsTypeChildId
                }else{
                    param.goodsTypeIds=this.goodsTypeFatherId
                }
            }
            if(this.isImport){
                if(this.inputTypeId!=''){
                    param.goodsTypeIds=this.inputTypeId
                }else if(this.inputTypeIdArr.length>0){
                    param.goodsTypeIds=this.inputTypeIdArr.join(',')
                }
            }
            if(this.fromCouture_id!=''){
                param.isHigh="Y"
            }
            // else{
            //     param.isHigh="N"
            // }
            if(this.isPreferred){
                param.isPreferred="Y"
            }
            // console.log(param)
            getGoodsSearch(param).then(res=>{
                // console.log("搜索商品结果",res);
                if(res.code==200){
                    res.data.rows.forEach(item=>{
                        item.sales=item.sales!=''?Number(item.sales):0;
                        item.virtualSales=item.virtualSales!=''?Number(item.virtualSales):0;
                    })
                    this.total=Number(res.data.total);
                    this.goodsList=res.data.rows;
                }else{
                    this.goodsList=[];
                }
            })
        },
        clickGoods(item){
            this.$router.push({
                path:'/furnitureDetail',
                query:{goodsId:item.goodsId}
            })
        },
        // 点击销量排序
        clickSales(){
            if(this.orderBy==1){
                this.orderBy=2
            }else{
                this.orderBy=1
            }
            this.searchGoods()
        },
        // 点击最新排序
        clickNewly(){
            if(this.orderBy==3){
                this.orderBy=4
            }else{
                this.orderBy=3
            }
            this.searchGoods()
        },
        // 点击价格排序
        clickPrice(){
            if(this.orderBy==5){
                this.orderBy=6
            }else{
                this.orderBy=5
            }
            this.searchGoods()
        },

        // 分类树
        gainGoodsTypeTree(){
			getGoodsTypeTree().then(res=>{
				// console.log("商品分类树",res);
				if(res.code==200){
					this.goodsTypeOpt=res.data.map(item=>{
                        return{
                            goodsTypeId:item.id,
                            goodsTypeName:item.label,
                        }
                    });
                    this.goodsTypeOpt.unshift({
                        goodsTypeName:"全部",
                        goodsTypeId:"",
                    })
                    // if(this.goodsTypeFatherId==''){
                    //     this.goodsTypeFatherId=res.data[0].id;
                    // }
                    this.gainGoodsTypeChildList()
				}
			})
		},
        // 根据等级查询分类列表
        gainLevelTypeList(){
            getGoodsTypeList({level:2}).then(res=>{
                // console.log("等级查询分类列表",res);
                if(res.code==200){
                    this.goodsTypeOpt=res.data;
                    if(this.goodsTypeFatherId==''){
                        this.goodsTypeFatherId=res.data[0].goodsTypeId;
                    }
                    this.gainGoodsTypeChildList()
                }
            })
        },
        // 根据id获取分类子列表
        gainGoodsTypeChildList(){
            if(this.goodsTypeFatherId==''){
                this.goodsTypeLevel3List=[];
                return
            }
            getGoodsTypeListChild({id:this.goodsTypeFatherId}).then(res=>{
                // console.log("子分类列表",res);
                if(res.code==200){
                    this.goodsTypeLevel3List=res.data;
                }
            })
        },
        fatherIdChange(){
            // // console.log(this.goodsTypeFatherId)
            sessionStorage.home_level2TypeId=this.goodsTypeFatherId;
            this.goodsTypeChildId="";
            this.triggerFromInput=false
            // this.gainGoodsTypeChildList();
            // this.gainFiltrateCondition();

            this.$router.push({
                path:"/merchandise",
                query:{...this.$route.query,t2:this.goodsTypeFatherId},
                notNew:1
            })
        },
        // 点击三级分类
        clickLevel3Type(item){
            if(this.goodsTypeChildId!=item.goodsTypeId){
                this.goodsTypeChildId=item.goodsTypeId;
                sessionStorage.home_typeId=this.goodsTypeChildId;
                this.currentPage4=1;
                
                this.triggerFromInput=false
                // this.gainFiltrateCondition();

                this.$router.push({
                    path:"/merchandise",
                    query:{...this.$route.query,t3:item.goodsTypeId},
                    notNew:1
                })
            }
        }
    },
    created() {
        if(this.isImport){
            this.pageSize=16;
        }

        // if(this.$route.query.fromBrand&&sessionStorage.brandId){
        //     this.fromBrand_id=sessionStorage.brandId
        // }
        if(this.$route.query.pa&&this.$route.query.pa.indexOf('bi')!=-1){
            var bIndex=this.$route.query.pa.indexOf('bi');
            var str=this.$route.query.pa.substring(bIndex+3)
            var bIndex2=str.indexOf('%');
            if(bIndex2==-1){
                this.fromBrand_id=str
            }else{
                var str2=str.substring(0,bIndex2);
                this.fromBrand_id=str2
            }
            // this.fromBrand_id=sessionStorage.brandId;
        }

        if(this.$route.query.fromCouture&&sessionStorage.goodsTypeId){
            this.fromCouture_id=sessionStorage.goodsTypeId
        }

        // if(this.$route.query.fromHome&&sessionStorage.home_typeId){
        //     this.triggerFromInput=false
        //     this.fromHome_id=sessionStorage.home_typeId;
        //     this.goodsTypeFatherId=sessionStorage.home_level2TypeId;
        //     if(sessionStorage.home_typeId!=sessionStorage.home_level2TypeId){
        //         this.goodsTypeChildId=sessionStorage.home_typeId
        //     }
        // }
        // if(sessionStorage.home_level2TypeId){
        //     this.goodsTypeFatherId=sessionStorage.home_level2TypeId;
        //     this.gainGoodsTypeChildList();
        //     if(sessionStorage.home_typeId){
        //         this.goodsTypeChildId=sessionStorage.home_typeId
        //     }
        // }
        if(this.$route.query.t3){
            this.triggerFromInput=false;
            this.fromHome_id=this.$route.query.t3;
            if(this.$route.query.t2){
                this.goodsTypeFatherId=this.$route.query.t2;
                this.gainGoodsTypeChildList();
            }
            if(this.$route.query.t3!=this.$route.query.t2){
                this.goodsTypeChildId=this.$route.query.t3
            }
        }

        if(this.$route.query.isPreferred){
            this.isPreferred=true
        }
        if(this.$route.query.p){
            this.currentPage4=Number(this.$route.query.p)
        }
        if(this.$route.query.pa){
            var arr=this.$route.query.pa.split('%');
            arr.forEach(f=>{
                var obj={}
                for(var key in this.filedRep){
                    if(this.filedRep[key]==f.split('_')[0]){
                        obj.filed=key
                        break
                    }
                }
                console.log(obj)
                obj.selected=f.split('_')[1].split('-')
                
                this.filtrateCondtion.push(obj)
            })
            
        }
        // if(sessionStorage.AOGEYA_filters){
        //     this.filtrateCondtion=JSON.parse(sessionStorage.AOGEYA_filters)
        // }
        this.gainFiltrateCondition();
        // this.gainGoodsTypeChildList();
        // this.gainLevelTypeList();
        this.gainGoodsTypeTree()
    },
    watch:{
        '$route'(n,o){
            location.reload()
        },
        'inputTypeId'(n,o){
            this.gainFiltrateCondition();
        }
    },
    beforeRouteEnter (to, from, next) {
        // // console.log("222",from);
        if(from.name!=null){
            sessionStorage.removeItem("AOGEYA_filters")
        }
        next()
    }
}
</script>

<style lang="less" scoped>
.merchandise{
    .interspace{
        // margin-top: 50px;
        // margin-top: 20px;
        margin-top: 10px;
        background: rgba(245, 245, 245, 1);
        height: 41px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: rgba(128, 128, 128, 1);
        padding: 0 22px;
        padding-right: 112px;
        position: relative;
        .space{
            width: 121px;
            height: 100%;
            box-sizing: border-box;
            .el-select{
                width: fit-content;
                width: 90px;
                /deep/.el-input{
                    // width: fit-content;
                }
                /deep/.el-input__inner{
                    // width: 60px;
                    padding: 0;
                    border: none;
                    background: transparent;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(128, 128, 128, 1);
                }
            }
        }
        .classify{
            flex: 1;
            height: 100%;;
            display: flex;
            align-items: center;
            .item{
                padding: 0 10px;
                height: 25px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 700;
                color: rgba(128, 128, 128, 1);
                margin-right: 20px;
                cursor: pointer;
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    background: rgba(37, 126, 93, 1);
                    color: rgba(255, 255, 255, 1);
                    font-weight: 400;
                }
            }
        }
        .expand{
            position: absolute;
            top: 50%;
            right: 22px;
            transform: translateY(-50%);
            width: 93px;
            height: 48.6px;
            width: 80px;
            height: 26px;
            opacity: 1;
            border-radius: 4px;
            border: 1px solid rgba(37, 126, 93, 1);
            font-size: 12px;
            font-weight: 400;
            color: rgba(37, 126, 93, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover{
                background: rgba(225, 242, 236, 1);                
            }
            i{
                margin-left: 4px;
                // font-size: 20px;
                transition: all 0.3s;
                &.up{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .kinds{
        position: relative;
        .lines{
            // height: 145.8px;
            height: 194.4px;
            overflow-y: hidden;
            transition: all 0.3s
        }
        .line{
            padding: 11px 22px;
            border-bottom: 1px solid rgba(237, 237, 237, 1);
            // height: 48px;
            .lineInner{
                display: flex;
                align-items: center;
                height: 25px;
                overflow: hidden;
                transition: all,0.3s
            }
            .kindName{
                width: 121px;
                box-sizing: border-box;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1)
            }
            &:last-child{
                border-bottom: none;
            }
            .subs{
                flex: 1;
                height: 100%;;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                position: relative;
                align-content: flex-start;
                .item{
                    padding: 0 10px;
                    height: 25px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    // margin-right: 20px;
                    cursor: pointer;
                    &.isBrand{
                        height: 54px;
                        margin-bottom: 10px;
                        position: relative;
                        // &>img{
                        //     border: 1px solid transparent;
                        //     &:hover{
                        //         // filter:brightness(0.7);
                        //         border-color: rgba(37, 126, 93, 1)
                        //     }
                        // }
                        &.active,&:hover{
                            background: transparent!important;
                            // img{
                            //     border-color: rgba(37, 126, 93, 1);
                            // }
                            .text{
                                display: flex;
                            }
                        }
                        .text{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: 1px solid #257e5d;
                            border-radius: 10px;
                            color: #257e5d;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fff;
                            display: none;
                        }
                    }
                    &>img{
                        width: 100px;
                        height: 54px;
                        border-radius: 10px;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        background: rgba(37, 126, 93, 1);
                        color: rgba(255, 255, 255, 1);
                        font-weight: 400;
                        &:hover{
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                    &:hover{
                        color: rgba(37, 126, 93, 1);
                    }
                }
                .multiOpe{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &>div{
                        width: 56px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #d8d8d8;
                        cursor: pointer;
                        font-size: 12px;
                        color: #333;
                        border-radius: 2px;
                        margin: 0 10px;
                        &.cancel{
                            background: #f5f5f5;
                        }
                        &.confirm{
                            background: #d83030;
                            color: #fff;
                            border-color: #d83030;
                            &.disabled{
                                background: #f7f7f7;
                                color: #ccc;
                                border-color: #eee;
                            }
                        }
                    }
                }
            }
            .opt{
                margin-left: 20px;
                align-self: stretch;
                .el-button.more{
                    width: 54px;
                    height: 20px;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    border:none;
                    .el-icon-arrow-down{
                        transition:all 0.3s;
                    }
                    &.trans .el-icon-arrow-down{
                        transform: rotate(180deg);
                    }
                }
                .el-button.multi{
                    width: 54px;
                    height: 20px;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                    &:focus{
                        border-color: #DCDFE6;
                    }
                    &:hover{
                        border-color: rgba(37, 126, 93, 1);
                        color: rgba(37, 126, 93, 1);
                    }
                    &.isMulti{
                        border-color: rgba(37, 126, 93, 1);
                        background: #e1f2ec;
                    }
                }
            }
        }
    }
    .filtrate{
        // margin-top: 40px;
        margin-top: 10px;
        height: 41px;
        padding: 6px 22px;
        box-sizing: border-box;
        background: rgba(245, 245, 245, 1);
        display: flex;
        justify-content: space-between;
        .condition{
            height: 28px;
            display: flex;
            align-items: center;
            .dimen{
                width: 248px;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 1);
                .el-button{
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    border-radius: 0;
                    padding: 0;
                    &.active{
                        background: #B83030;
                        color: #fff;
                    }
                }
            }
            .priceRange{
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                margin-left: 30px;
                .price{
                    width: 60px;
                    height: 100%;
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(237, 237, 237, 1);
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    box-sizing: border-box;
                    input{
                        text-align: right;
                        width: calc(100% - 10px);
                        outline: none;
                        border: none;
                        background: transparent;
                    }
                }
                .gap{
                    height: 1px;
                    background: #000;
                    width: 8px;
                    margin: 0 10px;
                }
                &>span:nth-child(1){
                    margin-right: 5px
                }
            }
            .sendTo{
                margin-left: 30px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                .el-select{
                    height: 28px;
                    width: 137px;
                    margin-left: 5px;
                    /deep/.el-input__inner{
                        height: 28px;
                        line-height: 28px;
                        border-radius: 0;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                    }
                    /deep/.el-input__icon{
                        line-height: 28px;
                    }
                }
            }
        }
        .view{
            display: flex;
            align-items: center;
            .total{
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
            }
            .ratio{
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                margin: 0 22px;
            }
            .swi{
                width: 90px;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 1);
                .el-button{
                    width: 50%;
                    height: 100%;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    border-radius: 0;
                    padding: 0;
                    &.active{
                        background: #B83030;
                        color: #fff;
                    }
                }
            }
        }
    }
    .container{
        // margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 337px;
            // margin-top: 40px;
            margin-top: 20px;
            margin-right: 40px;
            cursor: pointer;
            // background: rgba(245, 245, 245, 1);
            padding-bottom: 10px;
            padding-top: 9px;
            border-radius: 6px;
            &:nth-child(4n){
                margin-right: 0;
            }
            &>.img{
                // width: 337px;
                // height: 252px;
                width: 320px;
                height: 320px;
                margin: auto;
                position: relative;
                overflow: hidden;
                background: rgba(245, 245, 245, 1);
                border-radius: 6px;
                &>img{
                    // width: 337px;
                    // height: 252px;
                    width: 320px;
                    height: 320px;
                    // object-fit: cover;
                    object-fit: contain;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    border-radius: 6px;
                }
            }
            .price{
                font-size: 22px;
                font-weight: 700;
                color: rgba(200, 64, 57, 1);
                margin-top: 13px;
                padding: 0 10px;
                &>span{
                    font-size: 14px
                }
            }
            .intro{
                padding: 0 10px;
                font-size: 13px;
                // font-weight: 700;
                letter-spacing: -0.7px;
                line-height: 20px;
                color: rgba(56, 56, 56, 1);
                margin-top: 6px;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                line-clamp:2;
                .tag{
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: rgba(158, 110, 62, 1);
                }
            }
            .rela{
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding: 0 10px;
                position: relative;
                &>div{
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 700;
                    color: rgba(153, 153, 153, 1);
                    margin-right: 15px;
                    padding-right: 15px;
                    border-right: 1px solid rgba(237, 237, 237, 1);
                    &>img{
                        width: 13px;
                    }
                    &:last-child{
                        border-right: none;
                        margin-right: 0;
                    }
                    .num{
                        font-size: 12px;
                        font-weight: 700;
                        color: rgba(230, 77, 0, 1);
                        margin: 0 4px;
                    }
                }

                .flag{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    &>img{
                        width: 26px;
                        height: 15px;
                        margin-left: 10px;
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                }
            }
            &:hover{
                box-shadow: 0px 0px 8px  rgba(0, 0, 0, 0.15);

                .img{
                    img{
                        // width: 357px;
                        // height: 272px;
                        width: 340px;
                        height: 340px;
                    }
                }
            }
        }
        .empty{
            width: 100%;
            padding: 50px 0;
            text-align: center;
            &>img{
                width: 500px;
                margin-bottom: 10px;
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>